import React, {useState} from "react";
import angleDown from "./assets/angle-down2.svg";
import "./navmobile.css";
//import { AnimationOnScroll } from 'react-animation-on-scroll';

import X from "./assets/x.svg";

function NavMobile(props) {
    const [icon1, setIcon1] = useState(false);
  //const [icon2, setIcon2] = useState(false);
    return (
        <><img src={X} alt="X" className="closeNavMobile" onClick={e => {
            document.querySelector(".sofa-navbar-mobile").style.marginTop = "-100px";
        document.querySelector(".sofa-navbar-mobile").style.height = "0px";
      }}/>
    
    <div className="sofa-navbar-mobile-dropdown">
        
              <h1 onClick={e=> {
                document.querySelector(".dropId1").classList.toggle("classHeight");
                if(!icon1) {
                  document.querySelector(".imgClass1").style.transform = "rotateZ(180deg)";
                  setIcon1(true);
                }else {
                  document.querySelector(".imgClass1").style.transform = "rotateZ(0deg)";
                  setIcon1(false);
                }
                window.location="/"
                
                }}><span>Home</span><img className="imgClass1 imgIcon" src={angleDown} alt="angle" aria-hidden="true"/></h1>
              <ul className="dropId1 classHeight">
                <li onClick={e => {
                    document.getElementById("reponsiveNavLink1").click();
                }}><a id="reponsiveNavLink1" href={props.what}>What's SofaLight Business?</a></li>
                <li onClick={e => {
                    document.getElementById("reponsiveNavLink2").click();
                }}><a id="reponsiveNavLink2" href={props.productsUn}>Our products</a></li>
                {/* <li onClick={e => {
                    document.getElementById("reponsiveNavLink3").click();
                }}><a id="reponsiveNavLink3" href="#design">How we design</a></li> */}
                <li onClick={e => {
                    document.getElementById("reponsiveNavLink4").click();
                }}><a id="reponsiveNavLink4" href={props.articles}>Articles</a></li>
              </ul>
            </div>
           
            <ul className="mobileLinks">
                <li onClick={e => {
                  document.getElementById("responsiveAbout").click();
                }}><a id="responsiveAbout" href={props.about}>About us</a></li>
                <li onClick={e => {
                  document.getElementById("responsiveContact").click();
                }}><a id="responsiveContact" href={props.contact}>Contact us</a></li>
                <li onClick={e => {
                  document.getElementById("responsiveContact").click();
                }}><a id="responsiveContact" href={props.products}>Our Products</a></li>
                <li onClick={e => {
                  document.getElementById("responsiveCart").click();
                }}><a id="responsiveCart" href={props.cart}>Cart</a></li>
                <li>
                <a id="whatsapp" href="http://wa.me/250788689926" target="_blank" rel="noopener noreferrer">Chat With Us</a>
       </li>
              </ul>
        </>
    )
}

export default NavMobile;