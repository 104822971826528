import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import ViewProductsAdmin from './landingpages/productspage/viewproductsadmin/ViewProductsAdmin';
import CartTable from './landingpages/productspage/carttable/CartTable';
import ErrorBoundary from './landingpages/productspage/components/ErrorBoundary';
import ErrorMessage from './landingpages/productspage/components/ErrorMessage';
import ProductPreview from './landingpages/productspage/components/productpreview/ProductPreview'
import ErrorPageComponent from './ErrorPageComponent';
const router = createBrowserRouter([
    {
        path: "*",
        element:
        <ErrorPageComponent message="Page 404, Resource not found." />
    },
    {
        path: "/",
        element: <App />
    },
    {
        path: "/products",
        element: 
        <ErrorBoundary fallback={<ErrorMessage message="Loading all products..." />}>
            <ViewProductsAdmin />
            </ErrorBoundary>
    },
    {
        path: "/cart",
        element: <CartTable />
    },
    {
        path: "/productpreview",
        element: <ProductPreview />
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <RouterProvider router={router} />
);
