import React from "react";

import "./landingpages.css";
import WelcomePage from "./welcomepage/welcomepage";

function LandingPages() {
    return(
        <div>
            <WelcomePage />
        </div>
    )
}

export default LandingPages;