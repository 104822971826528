import React from "react";

function SlideItem(props) {
    return(
        <div className="swiper-zoom-container slideContainer">
         <img src={props.image} alt="slide" aria-hidden="true"/>
         <div className="slideContent">
            <h2 data-swiper-parallax="-100" className="h2Hero upAnim">{props.title}</h2>
            <p data-swiper-parallax="-300">{props.message}</p>
            {/* <button data-swiper-parallax="-600">{props.action}</button> */}
         </div>
        </div>
    )
}

export default SlideItem;