import React, { useRef } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import "./navbar.css";

// assets
import logo from "./assets/logo_4.png";
import angleDown from "./assets/angle-down.svg";


// import components
import NavHomeUnResponsive from "./navhome_unresponsive";
//import NavProductsUnResponsive from "./navproducts_unresponsive";
import NavMobile from "./navmobile";




function NavBar(props) {
  // const [icon1, setIcon1] = useState(false);
  // const [icon2, setIcon2] = useState(false);
  function onResize() {
    window.onresize = () => {
      if(window.innerWidth>=992) {
        document.querySelector(".sofa-navbar-mobile").style.height = "0";
      }
      
    }
  }

  onResize();
  return (
    <>
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <img id="logoId" src={logo} onClick={() => {
            window.location = "/"
          }} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={e => {
          document.querySelector(".sofa-navbar-mobile").style.marginTop = "0";
          document.querySelector(".sofa-navbar-mobile").style.height = "fit-content";
        }} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <div className="sofa-css-nav-dropdown">
              <h1 className="navBarH1" onClick={e => window.location="/"}><span>Home</span><img src={angleDown} alt="angle" aria-hidden="true"/></h1>
              <ul>
                <NavHomeUnResponsive shopnow={props.shopnow} what={props.what} productsUn={props.productsUn} design={props.design} article={props.articles}  />
              </ul>
            </div>
            <div className="sofa-css-nav-dropdown">
              {/* <h1 className="navBarH1"><span>Products</span><img src={angleDown} alt="angle" aria-hidden="true"/></h1>
              <ul>
                <NavProductsUnResponsive />
              </ul> */}
            </div>
            <a className="navBarH1 navBarH1Link navBarNonButton" href={props.about}>About Us</a>
            <a className="navBarH1 navBarH1Link navBarNonButton" href={props.contact}>Contact Us</a>
            <a className="navBarH1 navBarH1Link navBarNonButton" href={props.products}>Our Products</a>
            <a className="navBarH1 navBarH1Link navBarNonButton" href={props.cart}>Cart</a>

            
            <a className="navBarH1 AuthButtons1" id="whatsapp" href="http://wa.me/250788689926" target="_blank" rel="noopener noreferrer">Chat With Us</a>
       
            {/* <a href="#link">Signup</a>
            <a className="navBarH1 AuthButtons2" href="#link">Login</a> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <div className="sofa-navbar-mobile">
      <NavMobile 
        what={props.what}
        productsUn={props.productsUn}
        articles={props.articles}
        about={props.about}
        contact={props.contact}
        products={props.products}
        cart={props.cart}
      />
    </div>
    </>
  );
}

export default NavBar;