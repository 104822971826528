import React from "react";

import "./story.css";

function Story() {
    return(
        <><section id="aboutus" class="sofa-about-parallel">
        <div class="sofa-about-parallel-fixed">
            <div>
                <h3>Our Brief History</h3>
                <p id="sofa-about-story">SofaLight Business was founded by Songa Fred. SofaLight Business has a physical furniture shop offering all kinds of products including furniture, and accessories of every single shape, style, and size.
</p>         </div>
        </div>
    </section></>
    )
}

export default Story;