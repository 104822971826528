import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import "./productspreview.css";

import ErrorBoundary from "../../../productspage/components/ErrorBoundary";
import ErrorMessage from "../../../productspage/components/ErrorMessage";
import PreviewProducts from "../../../productspage/viewproductsadmin/PreviewProducts";

function ProductsPreview() {
   
    return(
        <>
        <div id="products" className="sofa-products-preview">
        <AnimationOnScroll animateIn="animate__bounceIn">
  <h2>OUR PRODUCTS</h2>
  <p>Checkout our products.</p>
</AnimationOnScroll>
<AnimationOnScroll animateIn="animate__bounceIn">
<section id="sofa-landing-products-preview">
   <div className="fixPosition">
   <ErrorBoundary fallback={<ErrorMessage message="Loading preview products..." />}>
                            <PreviewProducts id="productsUn" />
            </ErrorBoundary>
   </div>
    </section>
    </AnimationOnScroll>
    <div className="fixPosition">
    <button className="viewMoreProducts" onClick={e => {
        window.location = "/products"
    }}>View More Products</button>
    </div>
    </div>
        </>
    )
}

export default ProductsPreview;