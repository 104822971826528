import React from 'react'

import './Labels.css'

function Labels(props) {
  return (
    <div className='sofa_light_dashboard_furniturestore_components_labels'>
        <p>{props.title}</p>
    </div>
  )
}

export default Labels