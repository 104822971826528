import React from "react";
import "./NavHomeUnResponsive.css";

function NavHomeUnResponsive(props) {
    return (
        // shopnow={props.shopnow} what={props.what} productsUn={props.productsUn} article={props.articles}
        <div style={{width: '100vw', height: '100vh', display: 'flex', flexDirection: 'row'}}>
            <div className="quicklink_left">
                <h2>QUICK LINKS</h2>
                <p>SofaLight Business operates in Kigali, Rwanda and we build customized furniture, but we also have premade furnitures that can be sold immediately.</p>
                <a style={{color: 'white'}} href={props.shopnow} target="_blank" rel="noreferrer">Shop Now</a>
            </div>
            <div className="quicklink_right">
                <p onClick={e => {
                    document.getElementById("unreponsiveNavLink1").click();
                }}><a id="unreponsiveNavLink1" href={props.what}>What's SofaLight Business?</a></p>
                <p onClick={e => {
                    document.getElementById("unreponsiveNavLink2").click();
                }}><a id="unreponsiveNavLink2" href={props.productsUn}>Our products</a></p>
                <p onClick={e => {
                    document.getElementById("unreponsiveNavLink3").click();
                }}><a id="unreponsiveNavLink3" href={props.design}>How we design</a></p>
                <p onClick={e => {
                    document.getElementById("unreponsiveNavLink4").click();
                }}><a id="unreponsiveNavLink4" href={props.articles}>Articles</a></p>
            </div>
        </div>
    )
}

export default NavHomeUnResponsive;