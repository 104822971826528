import React from "react";

import "./gallery.css";

import design from "./design.svg";
import scale from "./scale.svg";
import proto from "./proto.svg";
import test from "./test.svg";
import present from "./present.svg";


function Gallery() {
    return(
        <>
        <div id="design" className="sofa-gallery-preview">
        <div className="sofa-gallery-img sofa-gallery-hover">
            <div className="sofa-gallery">
                <img alt="design" src={design} />
            </div>
            <div className="sofa-content">
                <h3>Design Concept:</h3>
                <p>The first step we take in the process is the idea or concept; this is the starting point from which everything else grows. How we come up with the idea may be through an organised brainstorming session or it may occur naturally while researching. </p>
            </div>
        </div>
        <div className="sofa-gallery-img sofa-gallery-hover">
            <div className="sofa-gallery">
                <img alt="scale" src={scale} /></div>
            <div className="sofa-content">
            <h3>Scale Model:</h3>
                <p>Next we build a small, scale model of the idea as a way of seeing how it will look and to identify any problems before proceeding to build a full scale prototype. Sometimes there can be unforeseen complications that don't show up on paper, and they can be dealt with at this stage before realising it later and wasting full sized materials in order to do so. </p>
            </div>
        </div>
        <div className="sofa-gallery-img sofa-gallery-hover">
            <div className="sofa-gallery">
                <img alt="proto" src={proto} /></div>
            <div className="sofa-content">
            <h3>Prototype:</h3>
               <p>The next step we build a to-scale (full size), working prototype.A prototype is basically the small scale model made full sized. If this is a once of piece, what this means is that we are in fact building the finished product while if the design is intended for mass production we are simply building a single piece to ensure all of the construction techniques and materials that will be used are suitable.</p>
            </div>
        </div>
        <div className="sofa-gallery-img sofa-gallery-hover">
            <div className="sofa-gallery">
                <img alt="test" src={test} /></div>
            <div className="sofa-content">
            <h3>Testing:</h3>
               <p>Once the prototype has been built, it needs to be tested. If it's a chair, someone needs to sit on it, if it's a shelf, something needs to be put on it, simple really. For the purposes of mass production, furniture should be stress tested and sometimes several prototypes are made so that a few can be broken in order to find out what the limits of the piece of furniture are.</p>
            </div>
        </div>
        {/* <div className="sofa-gallery-img">
            <div className="sofa-gallery"></div></div> */}
            <div className="sofa-gallery-img sofa-gallery-hover">
            <div className="sofa-gallery">
                <img alt="present" src={present} /></div>
            <div className="sofa-content">
            <h3>Presentation:</h3>
                <p>Once completed the finished piece should be photographed for portfolio purposes, and presented to the client for validation and delivery. If there is a client then once all of the testing has been completed, the piece is presented to them. They will either take it as it is or they ,may ask for the piece to be changed or tweaked in some way.</p>
            </div>
        </div>
    </div>
    </>
    )
}

export default Gallery;