import React from 'react'
import NavBar from './landingpages/welcomepage/components/navbar/navbar'

function ErrorPageComponent(props) {
  return (
    <>
    <NavBar 
                shopnow="/products"
                what="/#whatsofa"
                productsUn="/products"
                design="/#design"
                articles="/#posts"
                about="/#aboutus"
                contact="/#contactus"
                products="/products"
                cart="/cart"
            />
    <div style={{marginTop: '100px',width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <p style={{fontSize: '2em', fontWeight: 'bolder'}}>{props.message}</p>
    </div>
    </>
  )
}

export default ErrorPageComponent