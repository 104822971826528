import React from "react";

import "./footer.css";

import logo from "./assets/logo_4.png";
import insta from "./assets/instablack.svg";

function Footer() {
    return(
        <>
            <div id="contactus" className="footer">
                    <div className="footer-contact-top">
                        <img className="img1" src={logo} alt="logo" />
                        <p>We are located in Rwanda, Kigali city, Gasabo District - Kinamba </p>
                        <img onClick={e => {
                            document.getElementById("footerLink").click();
                        }} className="img2" src={insta} alt="insta" />
                        <a id="callLink" href="tel:+250788689926" target="_parent" rel="noreferrer">Call us on +250 788 689 926</a>
                        <a id="footerLink" href="https://www.instagram.com/sofa_light_business/" rel="noreferrer" target="_blank" style={{display: "none"}}>Connect with us</a>
                        <ul>
                            <li><a href="/#whatsofa">What's Sofa Light</a></li>
                            <li><a href="/#products">Our products</a></li>
                            <li><a href="/#design">How we design</a></li>
                            <li><a href="/#posts">Articles</a></li>
                        </ul>
                    </div>
                    <div className="footer-contact-bottom">
                        <p>@2023 copyright. All rights reserved.</p>
                    </div>
            </div>
        </>
    )
}

export default Footer;