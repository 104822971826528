import React, {useState} from 'react'

import "./CartTable.css"
import trash from "./trash.svg"

import Labels from '../components/labels/Labels';
import TextField from '../components/textfields/TextField'
import CustomTextField from '../components/customtextfield/CustomTextField'
import OrderButton from '../components/orderbutton/OrderButton';
import NavBar from '../../welcomepage/components/navbar/navbar';
import Footer from '../../welcomepage/components/footer/footer';
import formatPrice from '../viewproductsadmin/formatMoney';

// async function getData() {
//     let result = await fetch("https://www.backend.sofalightbusiness.com/cart")
//          .then(d => d.json())
//          .then(d => d).catch(e => console.log(e));
            
//     return result.items
//  }

function CartTable() {
    let data =JSON.parse(localStorage.getItem("cart_items"))
    

    const [user, setUser] = useState('')
    const [phone, setPhone] = useState(null)
    
    
  return (
    <>
    <NavBar 
                shopnow=""
                what="/#whatsofa"
                productsUn="/products"
                design="/#design"
                articles="/#posts"
                about="/#aboutus"
                contact="/#contactus"
                products="/products"
                cart="/cart"
            />
    <div className='sofa_light_dashboard_furniturestore_components_carttable'>
        <table>
            <thead>
            <tr>
                <th name="product">Product</th>
                <th name="price">Price</th>
                <th name="quantity">Quantity</th>
                <th name="total">Total</th>
                <th name="action">Action</th>
            </tr>
            </thead>
            <tbody>
                
           {data?
            data.map((d,k) => {
                
                 return(
                    (
                        <tr key={k}>
                        <td htmlFor="product">
                            <img className='thumbnail' src={d.item_img} alt="thumbnail" />
                            <div className='thumbnailDescription'>
                            <div>
                                <p>{d.item_name}</p>
                            </div>
                            {/* <div>
                                <p>Size: <span>Small</span></p>
                                <p>Color: <span>Golden</span></p>
                            </div> */}
                        </div>
                        </td>
                        <td htmlFor="price" className='money'>{formatPrice(d.item_price)} RWF</td>
                        <td htmlFor="quantity">
                        <div className='productActions'>
                   <button 
                        onClick={e => {
                            console.log("searching")
                          let newData = data;
                          newData.forEach(piece => {
                            if(piece.item_id === d.item_id && Number(piece.item_quantity)>1) {
                                console.log("found")
                                piece.item_quantity = String(Number(piece.item_quantity) - 1)
                            }
                          })
                          localStorage.setItem("cart_items",JSON.stringify(newData))
                          console.log("changed ",data)
                          window.location.reload()
                        }}>-</button>
                    <span>{d.item_quantity}</span>
                    <button onClick={e =>{
                      console.log("searching")
                      let newData = data;
                      newData.forEach(piece => {
                        if(piece.item_id === d.item_id && Number(piece.item_quantity)<30) {
                            console.log("found")
                            piece.item_quantity = String(Number(piece.item_quantity) + 1)
                        }
                      })
                      localStorage.setItem("cart_items",JSON.stringify(newData))
                      console.log("changed ",data)
                      window.location.reload()
                    }}>+</button>
                 </div>
                        </td>
                        <td htmlFor="total">
                         {formatPrice(Number(d.item_quantity)*d.item_price)} RWF
                        </td>
                        <td htmlFor="action" className='money'>
                            <div className='money_trash_div'>
                            {/* <p className=''>200,000 RWF</p> */}
                            <img onClick={e => {
                                let storeData = JSON.stringify(data.filter(da => da.item_id!==d.item_id))
                                localStorage.setItem('cart_items', storeData)
                                if(JSON.parse(localStorage.getItem('cart_items')).length<1) {
                                    localStorage.removeItem('cart_items')
                                }
                                window.location.reload();
                            }}className='trash' src={trash} alt="trash"/>
                            </div>
                        </td>
                    </tr>
                    )
                )
            }):<td>Add At Least One Item</td>
           }
                
                
            </tbody>
        </table>

    
    </div>
    <div className='sofa_light_dashboard_furniturestore_components_carttable'>
        <div className=''>
            <Labels title="Fill information and Order:" />
            <Labels title="Name:" />
            <TextField keepSync={setUser}  id="ordername" />
            <Labels title="Phone Number:" />
            <CustomTextField keepSync={setPhone} id="orderphone" />
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <OrderButton user={user} phone={phone} />
            </div>
        </div>
    </div>
    <Footer />
    </>
  )
}

export default CartTable