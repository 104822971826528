import React from "react";
import Hero from "./components/hero/hero";
import NavBar from "./components/navbar/navbar";
import Posts from "./components/posts/posts";
import Gallery from "./components/gallery/gallery";

import "./welcomepage.css";
import Story from "./components/story/story";
import Footer from "./components/footer/footer";

import ProductsPreview from "./components/productspreview/productspreview";

function WelcomePage() {
    return(
        <div>
            <NavBar 
                shopnow="/products"
                what="/#whatsofa"
                productsUn="/products"
                design="/#design"
                articles="/#posts"
                about="/#aboutus"
                contact="/#contactus"
                products="/products"
                cart="/cart"
            />
            <Hero />
            <ProductsPreview />
            <Gallery />
            <Story />
            <Posts />
            <Footer />
        </div>
    )
}

export default WelcomePage;