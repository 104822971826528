import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./hero.css";

import img1 from "./1.jpeg";
import img2 from "./2.jpeg";
import img3 from "./3.jpeg";

// import required modules
import { EffectCreative, Parallax, Pagination, Navigation, Autoplay } from "swiper";
import SlideItem from "./slide";

export default function Hero() {
  const data = [
    {
        img: img1,
        title: "What is SofaLight Business?",
        message: "We enjoy designing homes, working with the public, and building many different furnitures like chairs, living rooms and more. On this Website you can learn more about how we started and our design processes.",
        action: "Read More."
    },
    {
        img: img2,
        title: "Where is SofaLight Business?",
        message: "We are located in Rwanda, Kigali city, Gasabo District - Kinamba ",
        action: "Visit Us."
    },
    {
        img: img3,
        title: "SofaLight Business Products.",
        message: " We make furniture including tables, chairs, beds, desks, dressers, and cupboards.",
        action: "Buy Our Products."
    }
  ]
  return (
    <div id="whatsofa" className="sofa-hero">
      <Swiper
      modules={[EffectCreative, Parallax, Pagination, Navigation, Autoplay]}
        slidesPerView={1}
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        spaceBetween={0}
        loop={true}
        speed={1000}
        parallax={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false
      }}
        navigation={true}
        // onTransitionStart={(swiperCore) => {
        //   let start =500;
        //   document.querySelector(".h2Hero").style.transform = `translateX(${start}px)`;
        //     console.log(document.querySelector(".h2Hero"), `translateX(${start}px)`);
        // }}
        className="mySwiper"
      >
        
            {data.map((d,i) => (
                <SwiperSlide key={i}>
                <SlideItem image={d.img} title={d.title} message={d.message}  />
                {/* action={d.action} */}
                </SwiperSlide>
            ))}
        
      </Swiper>
    </div>
  );
}
