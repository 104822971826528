import React, {useState, useEffect} from 'react'
// import Brands from '../components/brands/Brands'
// import Category from '../components/category/Category'
// import PriceRange from '../components/pricerange/PriceRange'
// import Search from '../components/search/Search'
// import SortBy from '../components/sortby/SortBy'
import Product from '../components/product/Product';
import formatPrice from './formatMoney';
import './ViewProductsAdmin.css'



function PreviewProducts(props) {

  const [data, setData] = useState([])
  let getDataStatus = "get"
  

////// handle unauthorized access


////// end handling unauthorized access

  useEffect(() => {
    function handleResize() {
      if(window.innerWidth>1263) {
        document.querySelector(".products_show").style.gridTemplateColumns = "1fr 1fr 1fr"
      }
      if(window.innerWidth<1263) {
      document.querySelector(".products_show").style.gridTemplateColumns = "1fr 1fr"
    }
    if(window.innerWidth<893) {
      document.querySelector(".products_show").style.gridTemplateColumns = "1fr"
    }
}
    window.addEventListener('resize', handleResize)
  })



  useEffect(() => {
    async function getData() {
      let result = await fetch("https://www.backend.sofalightbusiness.com/sofalight/backend/api/products")
      .then(d => d.json())
      .then(d => d).catch(e => 
          {
              console.log(e)
          });
          setData(result);
    }
    
    getData()
    
  },[getDataStatus]);


  return (
    <>
    {/* <div id="preloaderScreenViewProductsAdmin">
      <div className='loader'>
        <div className='inner-loader'></div>
      </div>
    </div> */}
    <div id={props.id} className='sofa_light_dashboard_furniturestore_components_viewproducts'> 
        <div>
          
          <div className='products_show'>
          <>
            {data.length>0? 
            data.map((d,k) => {
              if(d.productInfo.productStatus.toLowerCase().trim() === "draft") {
                return <div style={{display: 'none'}} key={k}></div>
              }
              
              if(Number(d.productInfo.productPrice) < 3100000 && k<6) {
                return(
                    <Product productData={d} id={d._id} key={k} src={d.images[0][1]} productname={d.productInfo.productName} productprice={d.productInfo.productPrice}/>
                  )
              }
              return <div style={{display: 'none'}} key={k}></div>
            }):
            <h1>Loading products ...</h1>   
        }
        </>
          </div>
        </div>
    </div>
    </>
  )
}

export default PreviewProducts