import React, {useEffect, useState} from 'react'

import './Product.css'

import cart from './cart.svg'
import formatPrice from '../productpreview/formatMoney'


function Product(props) {
  const [buttonText, setButtonText] = useState('Add To Cart.')
  
  let checkInitButtonText = "check"
  useEffect(() => {
    const decideInitialButtonText = () => {
      if(localStorage.getItem('cart_items')) {
        let checkCartItems = JSON.parse(localStorage.getItem('cart_items'))
        // check 
        checkCartItems.forEach(item => {
          if(item.item_id === props.id) {
            setButtonText("Item Added.")
          }
        });
      }
    }
    decideInitialButtonText();
  },[checkInitButtonText,props.id])
  return (
    <div className='sofa_light_dashboard_furniturestore_components_product'>
      <a style={{display: "block", width: '300px', height: '300px'}} href="/productpreview" onClick={e => {
        localStorage.setItem('product_preview',JSON.stringify(props.productData))
      }} className='prodImg'>
        <img style={{width: '100%', height: '100%', borderTopLeftRadius: '10px',borderTopRightRadius: '10px'}} src={props.src} alt="products"/>
      </a>
      <div className='productDesc'>
        <p>{props.productname}</p>
        <p>{formatPrice(props.productprice)} RWF</p>
        <div className='productActions'>
          <button onClick={async e => {
           
            if(localStorage.getItem("cart_items")) {
              // if cart items exist toggle the item
              let items = JSON.parse(localStorage.getItem("cart_items"));
             
              // if items contains item
              let itemStatus = "";
              items.forEach(item => {
                if(item.item_id === props.id) {
                  itemStatus = "found item"
                }
              });
              if(itemStatus === "found item") {
                items = items.filter(el => el.item_id!==props.id)
                let stringItems = JSON.stringify(items);
                localStorage.setItem("cart_items",stringItems);
                setButtonText("Add To Cart.")
                e.target.removeAttribute('disabled')
                return
              }
              // if item is new
              let item = {item_img: props.src,item_id: props.id, item_name: props.productname, item_price: props.productprice, item_quantity: 2}
              items.push(item)
              let stringItems = JSON.stringify(items);
              localStorage.setItem("cart_items",stringItems)
            setButtonText("Item Added.")
            e.target.removeAttribute('disabled')
              return;
            }else {
              // else set cart items
              let item = {item_img: props.src,item_id: props.id, item_name: props.productname, item_price: props.productprice, item_quantity: 2}
              let items = []
              items.push(item)
              let stringItems = JSON.stringify(items);


            localStorage.setItem("cart_items",stringItems)
            setButtonText("Item Added.")
            e.target.removeAttribute('disabled')
            }
            

            


            
          }}><img src={cart} alt='cart' />{buttonText}</button>
          <button onClick={e => {
             if(JSON.parse(localStorage.getItem("cart_items"))) {
              if(JSON.parse(localStorage.getItem("cart_items")).length>0 && buttonText==='Item Added.') {
                console.log(buttonText)
                window.location = "/cart"
              }else {
                // else set cart items
              let item = {item_img: props.src,item_id: props.id, item_name: props.productname, item_price: props.productprice, item_quantity: 2}
              let items = JSON.parse(localStorage.getItem('cart_items'))
              items.push(item)
              let stringItems = JSON.stringify(items);


            localStorage.setItem("cart_items",stringItems)
            window.location = "/cart"
            return;
              }
             }else {
              // else set cart items
              let item = {item_img: props.src,item_id: props.id, item_name: props.productname, item_price: props.productprice, item_quantity: 2}
              let items = []
              items.push(item)
              let stringItems = JSON.stringify(items);


            localStorage.setItem("cart_items",stringItems)
            window.location = "/cart"
             }
            
          }}>Order Now.</button>
        </div>
      </div>
    </div>
  )
}

export default Product