import React from "react";

import "./posts.css";

function Posts() {
    return (
        <>
         <section id="posts">
        
        <div class="sofa-post">
            <div class="sofa-post-title">
                <p>
                    <span></span>
                    <span>The Future of Furniture Manufacturing and Design</span>
                </p>
            </div>
            <div class="sofa-post-content">
                <p>FDT Magazine explores the predictions for the future of furniture manufacturing and design. 
</p>
            </div>
            <div class="sofa-post-footer">
                <button onClick={e => {
                    document.getElementById("sofa-post-footer-article-link-1").click();
                }}>Read More</button>
                <a id="sofa-post-footer-article-link-1" target="_blank" style={{display: "none"}} href="https://www.furnituredesignindia.com/articles/20443/the-future-of-furniture-manufacturing-and-design" rel="noreferrer" alt="man">manufacturing</a>
            </div>
        </div>
        
        <div class="sofa-post">
            <div class="sofa-post-title">
                <p>
                    <span></span>
                    <span>Modern furniture manufacturing</span>
                </p>
            </div>
            <div class="sofa-post-content">
                <p>Modern methods of furniture construction are largely based on the availability of man-made materials such as reliable plywood, laminated board, chipboard, and hardboard as distinct from natural solid wood. </p>
            </div>
            <div class="sofa-post-footer">
            <button onClick={e => {
                    document.getElementById("sofa-post-footer-article-link-2").click();
                }}>Read More</button>
                <a id="sofa-post-footer-article-link-2" target="_blank" style={{display: "none"}} href="https://www.britannica.com/topic/furniture-industry/Modern-furniture-manufacturing" rel="noreferrer" alt="man">manufacturing</a>
            
            </div>
        </div>
        
        <div class="sofa-post">
            <div class="sofa-post-title">
                <p>
                    <span></span>
                    <span>Furniture Manufacturing Industry – A Sneak Peek</span>
                </p>
            </div>
            <div class="sofa-post-content">
                <p>Manufacturing is the process of production of articles for sale or use, with the help of labor, tools, machines, and biological or chemical formulation.</p>
            </div>
            <div class="sofa-post-footer">
            <button onClick={e => {
                    document.getElementById("sofa-post-footer-article-link-3").click();
                }}>Read More</button>
                <a id="sofa-post-footer-article-link-3" target="_blank" style={{display: "none"}} href="https://industrytoday.com/furniture-manufacturing-industry-a-sneak-peek/" rel="noreferrer" alt="man">manufacturing</a>
            
            </div>
        </div>
        
        <div class="sofa-post">
            <div class="sofa-post-title">
                <p>
                    <span></span>
                    <span>What is furniture manufacturing?</span>
                </p>
            </div>
            <div class="sofa-post-content">
                <p>Furniture manufacturing is the process used to make furniture from the designing, cutting fabrication and assembling using material such as wood.</p>
            </div>
            <div class="sofa-post-footer">
            <button onClick={e => {
                    document.getElementById("sofa-post-footer-article-link-4").click();
                }}>Read More</button>
                <a id="sofa-post-footer-article-link-4" target="_blank" style={{display: "none"}} href="https://www.priestbrothers.co.uk/general-news/what-is-the-furniture-manufacturing/#:~:text=Furniture%20manufacturing%20is%20the%20process,using%20material%20such%20as%20wood." rel="noreferrer" alt="man">manufacturing</a>
            
            </div>
        </div>
        
        <div class="sofa-post">
            <div class="sofa-post-title">
                <p>
                    <span></span>
                    <span>List of furniture types</span>
                </p>
            </div>
            <div class="sofa-post-content">
               <p>This is a list of furniture types. Furniture includes objects such as tables, chairs, beds, desks, dressers, and cupboards. These objects are usually kept in a house or other building to make it suitable or comfortable for living or working in.</p>
                </div>
            <div class="sofa-post-footer">
            <button onClick={e => {
                    document.getElementById("sofa-post-footer-article-link-5").click();
                }}>Read More</button>
                <a id="sofa-post-footer-article-link-5" target="_blank" style={{display: "none"}} href="https://en.wikipedia.org/wiki/List_of_furniture_types" rel="noreferrer" alt="man">manufacturing</a>
            
            </div>
        </div>
    </section>
        </>
    )
}

export default Posts;